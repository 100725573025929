<template>
    <div class="buildContent" style="">
        
        <div ref="builder" class="builder-input pt-12 pb-6">
            
        </div>
        <MediathequeV2DialogComponent ref="mediathequeComponent" @input="loadFile" @openDialogMedia="dialogMediatheque = true" style="z-index:111111111;" v-model="fileUrl" :openDialogMediatheque="dialogMediatheque" @close="closeDialogMediatheque" :prefilters="prefilters" :availableFilters="availableFilters" :upload="true" :returnUrl="true" />
    </div>
</template>
<script>
    import "./contentbuilder/contentbuilder.css"
    import MediathequeV2DialogComponent from "@/components/dialogs/MediathequeV2DialogComponent"
    import GenericDataService from '@/services/GenericDataService'

    export default {
        name: 'ContentBuilderEdition',
        components:{ MediathequeV2DialogComponent },
        props: ["model", "fieldName", "field", "props"],
        data() {
        return {
            obj: null,
            html : null,
            dialogMediatheque:false,
            fileUrl: "",
            prefilters: {
                "utilisation": "image_landing"
            },
            availableFilters: [],
            customTags: [],
            tempCss: {}
        }
    },
    watch: {
        tempCss: function (val) {
            if(val) {
                this.model['css'] = (val);
            }
        },
        html : function(val) {
            console.log('modif html')
            this.model[this.fieldName] = val;
        }
    },
    methods: {
        convert(rgb) { 
            if(rgb != ''){
                rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/); 
            
                return "#" + this.hexCode(rgb[1]) + this.hexCode(rgb[2]) 
                        + this.hexCode(rgb[3]); 
            } else {
                return '';
            }
        },
        hexCode(i) { 
            return ("0" + parseInt(i).toString(16)).slice(-2); 
        },
        closeDialogMediatheque(){
            this.dialogMediatheque = false
        },
        loadFile(url, element){
            element.src = url 
            this.html = this.obj.html()
            this.model[this.fieldName] = this.html;
        },
        isScriptAlreadyIncluded (src){
            const scripts = document.getElementsByTagName("script");
            for(let i = 0; i < scripts.length; i++) 
            if(scripts[i].getAttribute('src') === src) return true;
            return false;
        },
        loadLanguageFile(languageFile, callback){
            if(!this.isScriptAlreadyIncluded(languageFile)) {
                const script = document.createElement("script");
                script.src = languageFile; 
                script.async = true;
                script.onload = () => {
                    if(callback) callback();
                };
                document.body.appendChild(script);
            } else {
                if(callback) callback();
            }
        },
        initLPEditor(){
            const vm = this
            if(this.model && this.model[this.fieldName]) 
                this.html = this.model[this.fieldName];
            else
                this.html = "";

            // GenericDataService.getData("/landingpagev2/getAvailableVariables").then((response) => {
            //     this.customTags = response.data.data;
            // });

            document.querySelector('.builder-input').style.opacity = 0; // optional: hide editable area until content loaded

            // Load language file first
                this.loadLanguageFile('/contentbuilder/lang/fr.js', ()=>{

                    // Then init the ContentBuilder
                    vm.obj = new ContentBuilder({
                            
                        container: '.builder-input',
                        customTags: vm.customTags,
                        snippetModal: true,
                        snippetModalLeft: true,
                        previewStyle: 'bottom:30px;top:auto;left:300px;right:auto;', // default position for Live Preview window on open
                    
                        // clearPreferences: true, // reset preferences settings
                        zoom: 1,
                    
                        // To enable AI Assistant
                        sendCommandUrl: '/sendcommand',
                        // showDisclaimer: false,
                        startAIAssistant: false, // Auto open 'AI Assistant' panel
                        enableShortCommands: false,
                        speechRecognitionLang: 'en-US', 
                        triggerWords: {
                            send: ['send', 'okay', 'ok', 'execute', 'run'],
                            abort: ['abort', 'cancel'],
                            clear: ['clear', 'erase']
                        },
                        // AIModalStyle: 'right:300px;', // default position for AI Assistant panel on open
                        
                        // If using DeepGram for speech recognition, specify the speechTranscribeUrl.
                        // speechTranscribeUrl: 'ws://localhost:3002',
                        // The server implementation for ws://localhost:3002 can be found in server.js (Node.js code)

                        // Enabling AI image generation
                        textToImageUrl: '/texttoimage', 
                        upscaleImageUrl: '/upscaleimage',
                        // imageAutoUpscale: false,
                        // viewFileUrl: '/viewfile', // If using S3
                        
                        
                        // OPTIONAL:

                        // If you need to change some paths:
                        snippetUrl: '/assets/minimalist-blocks/content.js', // Snippet file
                        snippetPath: '/assets/minimalist-blocks/',  // Location of snippets' assets
                        modulePath: '/assets/modules/', 
                        assetPath: '/assets/', 
                        fontAssetPath: '/assets/fonts/', 
                        disableConfig: false,
                        // Load plugins (without using config.js file)
                        plugins: [
                            // { name: 'wordcount', showInMainToolbar: true, showInElementToolbar: true },
                            { name: 'style', showInMainToolbar: true, showInElementToolbar: true },
                            //{ name: 'symbols', showInMainToolbar: true, showInElementToolbar: false },
                        ],
                        pluginPath: '/contentbuilder/', // Location of the plugin scripts
                        animateModal: false,
                        defaultRepresentation: 'HEXA',
                        // Open asset/file browser (can be replaced with your own file/asset manager application)
                        // imageSelect: 'assets.html',
                        // videoSelect: 'assets.html',
                        // audioSelect: 'assets.html',
                        // fileSelect: 'assets.html',
                        // mediaSelect: 'assets.html', // for images and videos
                        // You can replace it with your own asset/file manager application
                        // or use: https://innovastudio.com/asset-manager

                        // Or use custom:
                        // onImageSelectClick: () => {  }, 
                        // onVideoSelectClick: () => {  },
                        // onAudioSelectClick: () => {  },
                        // onFileSelectClick: () => {  },
                        // onMediaSelectClick: () => {  }, 
                        onAdd: (html) => {
                            html = html.replace(new RegExp(encodeURIComponent('{token}'), 'g'), 
                            vm.$store.state.auth.accessToken);
                            html = html.replace(new RegExp(encodeURIComponent('{account_id}'), 'g'), 
                            vm.$store.state.auth.currentAccountId);
                            html = html.replace(new RegExp(encodeURIComponent('{apiUrl}'), 'g'),
                            process.env.VUE_APP_API_URL);
                            return html;
                        },
                        onChange: function(){
                            console.log('change')
                            vm.$emit('landingChange', true)

                                let css = {
                                    maxWidth: document.getElementsByClassName('builder-input')[0].style.maxWidth,
                                    backgroundColor: vm.convert(document.getElementsByClassName('builder-input')[0].style.backgroundColor),
                                    containerBackgroundColor: vm.convert(document.getElementsByClassName('contentbuildercontainer')[0].style.backgroundColor),
                                    morecss: document.getElementsByClassName('contentbuildercontainer')[0].dataset.morecss
                                }
                                vm.tempCss = css;
                                this.builder.saveImages('', () => {
                                    vm.html = this.builder.html();
                                    vm.model[vm.fieldName] = vm.html;
                                    vm.$emit('landingChange', false)
                                }, (img, base64, filename) => {
                                    let jsonData = {
                                        'title': filename,
                                        'meta': {
                                            "utilisation": "image_landing"
                                        },
                                        'base64': base64
                                    };
                                    console.log(jsonData)
                                    GenericDataService.postData(vm.mediaUrl + "upload?key=" + vm.apiKey, jsonData).then((response) => {
                                        let url = response.data.media.url;
                                        if(url) {
                                            img.setAttribute('src', url);
                                            img.setAttribute('data-src', url);
                                        }
                                    });
                                })
                            
                            
                        },
                        onImageSelectClick: (e)=>{ 
                            this.$refs.mediathequeComponent.open(document.querySelector('.elm-active'))
                        },
                        onImageUpload: (e)=>{ 
                            uploadFile(e, (response)=>{
                                if(!response.error) {
                                    const uploadedFileUrl = response.url;
                                    if(uploadedFileUrl) obj.value.returnUrl(uploadedFileUrl);
                                }
                            });
                        },
                        onVideoUpload: (e)=>{
                            uploadFile(e, (response)=>{
                                if(!response.error) {
                                    const uploadedFileUrl = response.url;
                                    if(uploadedFileUrl) obj.value.returnUrl(uploadedFileUrl);
                                }
                            });
                        },
                        onAudioUpload: (e)=>{
                            uploadFile(e, (response)=>{
                                if(!response.error) {
                                    const uploadedFileUrl = response.url;
                                    if(uploadedFileUrl) obj.value.returnUrl(uploadedFileUrl);
                                }
                            });
                        },
                        onMediaUpload: (e)=>{  // for image & video
                            uploadFile(e, (response)=>{
                                if(!response.error) {
                                    const uploadedImageUrl = response.url; 
                                    if(uploadedImageUrl) obj.value.returnUrl(uploadedImageUrl);
                                }
                            });
                        },
                        onFileUpload: (e)=>{  // for file/document
                            uploadFile(e, (response)=>{
                                if(!response.error) {
                                    const uploadedImageUrl = response.url; 
                                    if(uploadedImageUrl) obj.value.returnUrl(uploadedImageUrl);
                                }
                            });
                        }, 
                        
                        useLightbox: true,
                        themes: [ // color preview, class name & css file
                            ['#ffffff','',''],
                            ['#282828','dark','contentbuilder/themes/dark.css'], 
                            ['#0088dc','colored','contentbuilder/themes/colored-blue.css'],
                            ['#006add','colored','contentbuilder/themes/colored-blue6.css'],
                            ['#0a4d92','colored','contentbuilder/themes/colored-darkblue.css'],
                            ['#96af16','colored','contentbuilder/themes/colored-green.css'],
                            ['#f3522b','colored','contentbuilder/themes/colored-orange.css'],

                            ['#b92ea6','colored','contentbuilder/themes/colored-magenta.css'],
                            ['#e73171','colored','contentbuilder/themes/colored-pink.css'],
                            ['#782ec5','colored','contentbuilder/themes/colored-purple.css'],
                            ['#ed2828','colored','contentbuilder/themes/colored-red.css'],
                            ['#f9930f','colored','contentbuilder/themes/colored-yellow.css'],
                            ['#13b34b','colored','contentbuilder/themes/colored-green4.css'],
                            ['#333333','colored-dark','contentbuilder/themes/colored-dark.css'], 
                            
                            ['#dbe5f5','light','contentbuilder/themes/light-blue.css'],
                            ['#fbe6f2','light','contentbuilder/themes/light-pink.css'],
                            ['#dcdaf3','light','contentbuilder/themes/light-purple.css'],
                            ['#ffe9e0','light','contentbuilder/themes/light-red.css'],
                            ['#fffae5','light','contentbuilder/themes/light-yellow.css'],
                            ['#ddf3dc','light','contentbuilder/themes/light-green.css'],
                            ['#c7ebfd','light','contentbuilder/themes/light-blue2.css'],

                            ['#ffd5f2','light','contentbuilder/themes/light-pink2.css'],
                            ['#eadafb','light','contentbuilder/themes/light-purple2.css'],
                            ['#c5d4ff','light','contentbuilder/themes/light-blue3.css'],
                            ['#ffefb1','light','contentbuilder/themes/light-yellow2.css'],
                            ['#fefefe','light','contentbuilder/themes/light-gray3.css'],
                            ['#e5e5e5','light','contentbuilder/themes/light-gray2.css'],
                            ['#dadada','light','contentbuilder/themes/light-gray.css'],

                            ['#3f4ec9','colored','contentbuilder/themes/colored-blue2.css'],
                            ['#6779d9','colored','contentbuilder/themes/colored-blue4.css'],
                            ['#10b9d7','colored','contentbuilder/themes/colored-blue3.css'], 
                            ['#006add','colored','contentbuilder/themes/colored-blue5.css'],
                            ['#e92f94','colored','contentbuilder/themes/colored-pink3.css'],
                            ['#a761d9','colored','contentbuilder/themes/colored-purple2.css'],
                            ['#f9930f','colored','contentbuilder/themes/colored-yellow2.css'],

                            ['#f3522b','colored','contentbuilder/themes/colored-red3.css'],
                            ['#36b741','colored','contentbuilder/themes/colored-green2.css'],
                            ['#00c17c','colored','contentbuilder/themes/colored-green3.css'],
                            ['#fb3279','colored','contentbuilder/themes/colored-pink2.css'],
                            ['#ff6d13','colored','contentbuilder/themes/colored-orange2.css'], 
                            ['#f13535','colored','contentbuilder/themes/colored-red2.css'],
                            ['#646464','colored','contentbuilder/themes/colored-gray.css'],

                            ['#3f4ec9','dark','contentbuilder/themes/dark-blue.css'],
                            ['#0b4d92','dark','contentbuilder/themes/dark-blue2.css'],
                            ['#006add','dark','contentbuilder/themes/dark-blue3.css'],
                            ['#5f3ebf','dark','contentbuilder/themes/dark-purple.css'],
                            ['#e92f69','dark','contentbuilder/themes/dark-pink.css'],
                            ['#4c4c4c','dark','contentbuilder/themes/dark-gray.css'],
                            ['#ed2828','dark','contentbuilder/themes/dark-red.css'],

                            ['#006add','colored','contentbuilder/themes/colored-blue8.css'],
                            ['#ff7723','colored','contentbuilder/themes/colored-orange3.css'],
                            ['#ff5722','colored','contentbuilder/themes/colored-red5.css'],
                            ['#f13535','colored','contentbuilder/themes/colored-red4.css'],
                            ['#00bd79','colored','contentbuilder/themes/colored-green5.css'],
                            ['#557ae9','colored','contentbuilder/themes/colored-blue7.css'],
                            ['#fb3279','colored','contentbuilder/themes/colored-pink4.css'],
                        ],

                    })

                    this.obj.loadSnippets('/assets/minimalist-blocks/content.js') // Load snippet file


                    let html = this.html;
                    
                    let settings = {
                        "data-id": -1,
                        "apiUrl": process.env.VUE_APP_API_URL,
                        "token": vm.$store.state.auth.accessToken,
                        "account_id": vm.$store.state.auth.currentAccountId
                    }
                    
                    html = html.replace(/data-module="formulaire"([^>]+)data-settings="(.*?)"/gm, `data-module="formulaire" $1 data-settings="${encodeURIComponent(JSON.stringify(settings))}"`);
                    this.html = html
                    
                    
                    
                this.obj.loadHtml(html);
                
                if(vm.model['css']) {
                    document.getElementsByClassName('builder-input')[0].style.maxWidth = vm.model['css'].maxWidth;
                    document.getElementsByClassName('builder-input')[0].style.backgroundColor = vm.model['css'].backgroundColor;
                    document.getElementsByClassName('contentbuildercontainer')[0].style.backgroundColor = vm.model['css'].containerBackgroundColor;
                    document.getElementsByClassName('contentbuildercontainer')[0].dataset.morecss = vm.model['css'].morecss;
                    this.tempCss = vm.model['css'];
                }
                setTimeout(() => {
                    document.getElementById('_cbhtml').querySelector('.is-elementrte-tool').style.display = 'flex';
                    document.getElementById('_cbhtml').querySelector('.is-elementrte-tool').style.left = '812px';
                    document.getElementById('_cbhtml').querySelector('.is-elementrte-tool').querySelectorAll('[data-align]').forEach((el) => {
                        el.style.display = 'none';
                    });
                    document.getElementById('_cbhtml').querySelector('[data-type="HEXA"]').click();
                }, 200);
                
                document.querySelector('.builder-input').style.opacity = 1; 

                })

        },
        getToken(){
            return this.$store.state.auth.accessToken;
        },
        destroyEditor(){
            if(this.obj) this.obj.destroy();
        }
    },
    computed: {
        mediaUrl(){
            return this.$store.state.auth.currentUser.accountParameters.mediaUrl
        },
        apiKey(){
            return this.$store.state.auth.currentUser.accountParameters.mediaApiKey
        }
    },
    mounted() {
       setTimeout(()=>{ 
            this.initLPEditor();     
        }, 200);  
    },
    destroyed() {
        if(this.obj) this.obj.destroy();
    },
}

</script>
<style lang="scss">
    // .sd-btn {
    //     pointer-events: none; // Ça fou la merde ici : #1506506631
    // }
    .contentbuildercontainer {
        span, applet, object, iframe,
        blockquote, pre,
        a, abbr, acronym, address, big, cite, code,
        del, dfn, em, img, ins, kbd, q, s, samp,
        small, strike, strong, sub, sup, tt, var,
        b, u, i, center,
        dl, dt, dd, ol, ul, li,
        fieldset, form, label, legend,
        table, caption, tbody, tfoot, thead, tr, th, td,
        article, aside, canvas, details, embed, 
        figure, figcaption, footer, header, hgroup, 
        menu, nav, output, ruby, section, summary,
        time, mark, audio, video {
            padding: 0;
            border: 0;
            vertical-align: baseline;
        }
        h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p {
            //font-family:unset !important;
            //font-size: initial;
            text-transform: unset;
            color:unset;
            
        }
        p {
            font-size: initial;
        }
        h5, .h5 {
            font-weight: 600 !important;
        }
        .flex, .child-flex > * {
            flex:initial;
        }
    }
    .buildContent {
        width: 100%; 
        padding-top:30px;
    }
    .conception {
        .buildContent {
            width: 100%; 
            padding-top:60px;
        }
    }
    .is-modal-close {
        display:none !important;
    }
    .snippetwindow {
        height: 80vh !important;
        top: calc(50% - 34vh) !important;
    }
    .is-elementrte-tool, .is-rte-tool {
        top:140px !important;
    }
    #_cbhtml .is-tool.is-iframe-tool  {
        background: rgba(0, 0, 0, 0.6) !important;
    }
</style>