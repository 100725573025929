<template>
    <div class="col col-12 pa-0 input-form" :style="field.props.hidden ? 'display:none; ' : ''">
        <p v-if="field.title" style="width:100%;" class="label mb-1">
            <b>{{$t(field.title)}}</b>
            <span v-if="field.props && field.props.required" class="error--text"> *</span>
            <infoTooltip v-if="field.tooltip" :tooltip="field.tooltip" />
        </p>

        <v-btn-toggle hide-details dense group borderless :mandatory="(field.props && field.props.required && !field.props.hasOwnProperty('mandatory')) ? true : (field.props.hasOwnProperty('mandatory') ? field.props.mandatory : false)" v-model="model[fieldName]" active-class="btnActive" :class="field.props && field.props['hide-details'] ? '' : 'mb-4'">
            <v-btn dense dark depressed class="white--text btnSpec" :disabled="(property.hasOwnProperty('disabled') && property.disabled)" 
                :style="model[fieldName] == property.value ? 'background-color:var(--v-primary-base) !important;' : ((property.hasOwnProperty('disabled') && property.disabled) ? 'background-color:var(--v-lightgrey-base) !important;' : 'background-color:var(--v-darkgrey-base) !important;')" 
                v-show="!property.hide" v-for="property in items" 
                :key="property.value" 
                :value="property.value"> 
                {{$t(property.label)}}
            </v-btn>
        </v-btn-toggle>
    </div>
</template>
<script>
import infoTooltip from '@/components/infoTooltip';
import GenericDataService from '../../../services/GenericDataService';

export default {
  props: ["model", "fieldName", "field"],
  components: { infoTooltip },
  data() {
    return {
        items: []
    };
  },
  mounted() {
    if(this.field.selectDataUrl){
        GenericDataService.getData(this.field.selectDataUrl.replace(/{{(.*?)}}/g, this.replaceFilter)).then((response) => {
            this.items = response.data.data;
        });
    } else
        this.items = this.field.props.items ? this.field.props.items : this._.uniqBy(this.field.properties,(obj) => obj.value);
  },
  methods: {
    // Permet de remplacer les filtres dans l'URL Ajax par les valeurs du model
    replaceFilter(match, fieldName) {
        if(this.field.searchInObject) {
            let searchKeys = fieldName.split('.');
            if(searchKeys.length === 2) // J'ai pas trouvé mieux ^^
                return (this.model[searchKeys[0]] && this.model[searchKeys[0]][searchKeys[1]]) ? this.model[searchKeys[0]][searchKeys[1]] :( this.model[searchKeys[0]] ? this.model[searchKeys[0]] : '0');
        } else if(this.model[fieldName]) {
            return this.model[fieldName];
        }
        return '0';  // Attention problème si id = 0
    },
  },
};
</script>

<style lang="scss">
    .v-btn-toggle > .v-btn.v-btn {
        border-radius:5px !important;
    }
    .v-btn-toggle .v-btn--disabled {
        background-color: #CCC !important;
    }
    .btnSpec {
        border-radius:5px;
    }
</style>